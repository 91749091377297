import { useNavigate } from 'react-router-dom'
import React, { type JSX, useEffect } from 'react'
import ReactGA from 'react-ga4'

const WeddingQR = (): JSX.Element => {
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.event({
      category: 'QR',
      action: 'QR A Voda',
      label: 'QR A Voda'
    })
    navigate('/pre-wedding', { replace: true })
  })

  return (
    <div>Redirixindo...</div>
  )
}

export default WeddingQR
