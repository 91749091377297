import React, { type JSX, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const PreWeddingQR = (): JSX.Element => {
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.event({
      category: 'QR',
      action: 'QR Pre Voda',
      label: 'QR Pre Voda'
    })
    navigate('/pre-wedding', { replace: true })
  }, [])

  return (
    <div>Redirixindo...</div>
  )
}

export default PreWeddingQR
