export const WEDDING_DATE = new Date('2023-09-30T13:00:00.000+02:00')

export const GA_TRACKING_ID = 'G-SNGZD3R5WX'

export const MAPBOX_GL_TOKEN = 'pk.eyJ1IjoicnZhYW1vbmRlIiwiYSI6ImNsaWRma3pqaTA5OGkzcXMwNXgxN3Uwb2QifQ.6zysrEtQm3zYHoxgheF2jg'

export const ABBEY_LATITUDE = 42.253460144552335
export const ABBEY_LONGITUDE = -8.1394978343253
export const BALNEARY_LATITUDE = 42.258806534636875
export const BALNEARY_LONGITUDE = -8.153823677565406

export const LINK_TO_ABBEY = 'https://goo.gl/maps/2eu8RbyKYoPKnEY79'
export const LINK_TO_STORE = 'https://goo.gl/maps/Rpq9cgRtqRJDDwWo6'

export const URL_FIREBASE_STORAGE = 'https://firebasestorage.googleapis.com/v0/b/a-nosa-voda.appspot.com/o/'
export const selYesNo = [
  { value: '1', label: 'Si' },
  { value: '0', label: 'Non' }
]
