import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCzPEs0f6LthtNcO3LMRy6-fceF6ECaG10',
  authDomain: 'a-nosa-voda.firebaseapp.com',
  projectId: 'a-nosa-voda',
  storageBucket: 'a-nosa-voda.appspot.com',
  messagingSenderId: '408314910142',
  appId: '1:408314910142:web:8dd2d2437e0d816da995ef',
  measurementId: 'G-2DSY2ENJ1F'
}

export const FirebaseApp = initializeApp(firebaseConfig)
export const FirebaseAuth = getAuth(FirebaseApp)
export const FirebaseDB = getFirestore(FirebaseApp)
