import React, { type JSX } from 'react'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import { type FirebaseResponse, type FormRsvpData } from '../../shared/types'
import { getAllRsvp } from '../../services/firebaseService'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'

const Export = (): JSX.Element => {
  const exportData = (): void => {
    const fetchData = async (): Promise<FirebaseResponse<FormRsvpData[]>> => {
      return await getAllRsvp()
    }
    void fetchData().then((documents: FirebaseResponse<FormRsvpData[]>) => {
      generateExcel(documents.data)
    })
  }

  const generateExcel = (excelData: any[] | undefined): void => {
    if (excelData == null) return

    const excelDataMapped = excelData.flatMap((data) => {
      return data.names.map((name: string) => {
        const bus = data.bus === '1' ? 'Si' : 'Non'
        const sleep = data.sleep === '1' ? 'Si' : 'Non'

        return {
          Fecha: new Date(data.createdAt.seconds * 1000).toLocaleString(),
          Nombre: name,
          Email: data.email,
          'Tipo de Asistencia': data.assistanceType.join(', '),
          Bus: bus,
          'Lugar de Recollida': data.busWhere,
          Aloxamento: sleep,
          'Número de Noites': data.nights ?? '-',
          'Número Habitacións': data.rooms ?? '-',
          'Intolerancias Alimentarias': data.intolerances ?? '-',
          Mensaje: data.message
        }
      })
    })

    console.log(excelDataMapped)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'rsvp'
    const ws = XLSX.utils.json_to_sheet(excelDataMapped)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div className={'flex flex-col justify-center items-center h-screen'}>
      <button
        onClick={exportData}
        className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center'
      >
        <CloudArrowDownIcon className='h-7 w-7 mr-2' aria-hidden="true" />
        DESCARGAR EXCEL
      </button>
    </div>
  )
}

export default Export
