import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import './index.css'
import App from './App'
import { GA_TRACKING_ID } from './shared/constants'

ReactGA.initialize(GA_TRACKING_ID)

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
