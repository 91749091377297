import React, { type JSX } from 'react'

const Footer = (): JSX.Element => {
  return (
    <div>
      <div className="flex justify-center items-center bg-primary-100 text-primary-600 h-16">
        <p>#ANosaVoda</p>
      </div>
    </div>
  )
}

export default Footer
