import { type PlacesState } from './PlacesProvider'

interface PlacesAction {
  type: 'setUserLocation'
  payload: [number, number]
}

export const placesReducer = (state: PlacesState, action: PlacesAction): PlacesState => {
  switch (action.type) {
    case 'setUserLocation':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
