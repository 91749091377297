import React, { type JSX } from 'react'
import HText from '../commons/HText'
import { SelectedPage } from '../../shared/types'
import { motion } from 'framer-motion'
import Map from '../map/Map'
import RsvpForm from '../rsvp-form/RsvpForm'
import Countdown from '../countdown/Countdown'
import {
  LINK_TO_STORE,
  WEDDING_DATE
} from '../../shared/constants'
import Event from '../event/Event'
import { AssistanceType } from '../../enums/assistanceType'
import StoreCarousel from '../storeCarousel/StoreCarousel'
import WhereSleep from './WhereSleep'

interface Props {
  setSelectedPage: (page: SelectedPage) => void
  type: AssistanceType
}

const WeddingSection = ({ setSelectedPage, type }: Props): JSX.Element => {
  return (
    <section id={SelectedPage.Wedding} className='gap-16 md:h-full md:pb-0 px-5'>

      <div className='mt-10'>
        <Countdown targetDate={WEDDING_DATE}/>
      </div>

      <div
        className="md:flex mx-auto items-center justify-center md:h-5/6 md:w-3/5"
      >
        <div className="z-10 mt-10">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 }
            }}
          >
            <HText>A Nosa Voda</HText>
            <p className="mt-8 text-sm text-justify">
              O vindeiro día <span className='font-bold'>30 de setembro</span>, esperamos celebrar unha festa que
              faga historia e que quede gravado nos vosos recordos como quedará nos nosos corazóns. Por iso, esperámoste
              no Balneario de <span className='font-bold'>Arnoia Caldaria as 13:00h</span></p>
            <div>
              <img src='/images/caldaria-garden.jpg' alt="Arnoia Caldaria" className='w-100 mt-5 rounded-lg'/>
            </div>
          </motion.div>
        </div>
      </div>

      <div
        className="md:flex mx-auto items-center justify-center md:h-5/6 md:w-3/5"
      >
        <div className="z-10 mt-10  w-full">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 }
            }}
          >
            <div className='mt-16'>
              <HText>Ubicación</HText>
              <div className='mt-2'>
                <p className="mt-8 text-sm text-justify">
                  Celebraremos a cerimonia, a sesión vermú o banquete e a troula sempre dentro do recinto, en pleno
                  corazón do Ribeiro.
                </p>
                <p className="text-sm text-justify">
                  Este hotel balneario atópase nun entorno boscoso a veira do río Miño, a 4 km do centro da localidade
                  histórica de Ribadavia.
                </p>
                <p className="text-sm text-justify">
                  Enderezo: Acceso Vila Termal, 1, 32417 A Arnoia. Ourense
                </p>
                <div className='mt-8'>
                  <Map/>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="md:flex mx-auto items-center justify-center md:h-5/6 md:w-3/5">
        <WhereSleep/>
      </div>

      <div className="md:flex mx-auto items-center justify-center md:h-5/6 md:w-3/5">
        <div className='mt-16'>
          <HText>Os nosos eventos</HText>
          <div>
            {
              type === AssistanceType.Training
                ? <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 }
                  }}
                >
                  <Event
                    title='O Adestramento'
                    image='warehouse.jpg'
                    description='Toda carreira de fondo necesita dun bo adestramento, por iso queremos que o vivas dende dentro'
                    date='Xoves, 28 de setembro de 2023'
                    time='12:00'
                    location='Adega (ver ubicación)'
                    link={LINK_TO_STORE}
                  >

                    <div className='px-5'>
                      <StoreCarousel/>
                    </div>
                  </Event>
                </motion.div>
                : <></>
            }

            {
              type === AssistanceType.PreWedding || type === AssistanceType.Training
                ? <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 }
                  }}
                >
                  <Event
                    title='A Previa'
                    image='abbey.jpg'
                    description='Ocio e natureza na comarca do Ribeiro. A tan só 1,5 quilómetros do Hotel Balneario de Arnoia.'
                    date='Venres, 29 de setembro de 2023'
                    time='18:00'
                    location='Abadía Caldaria'
                    link='https://goo.gl/maps/wFrRD1WjQysSEMbf8'
                  >
                    <div className='px-5'>
                      <div>
                        <p className='text-justify'>
                          Queremos que os nosos asistan a cerimonia co mellor do ambientes.
                          Que mellor forma que pondo caras a todas historietas de anécdotas que nos escoitades mil e unha
                          veces.
                          Queremos que neste tardeo xuntos facervos parte da nosa historia.
                          Non imos contarvos nada mais por agora deste evento.. polo que teredes que estar atentos as
                          novidades.
                        </p>
                        <p className="mt-5">
                          ABADÍA CALDARIA <br/>
                          Outeiro Cruz s/n, Arnoia <br/>
                          32417 – Ourense.
                        </p>
                      </div>
                      <div className='mt-5'>
                        <span className='text-lg text-slate-500 italic block underline'>Habitacións</span>
                        <p className='text-justify'>
                          Unhas instalacións coidadas ata o más mínimo detalle, ofrecendo espazos de gran beleza e
                          comodidade.<br/>
                          Abadía de Arnoia dispón de 16 habitacións dobres decoradas de forma individual e diferente e
                          acorde
                          coa sobriedade do edificio.<br/>
                          Dispón de 16 habitacións que reservaremos para os que asistades os dous días e teñades máis
                          ganas de
                          troula
                        </p>
                        <div>
                          <img src='/images/room.png' alt='Habitacións' className='w-full mt-5 rounded-lg'/>
                        </div>
                        <p className='mt-3'>
                          <span className='text-lg text-slate-500 italic block underline'>Prezos</span>
                          Habitación Individual: 60€/noite<br/>
                          Habitación Sinxela (2 persoas): 90€/noite<br/>
                          Habitación Triple (3 persoas): 110€/noite
                        </p>
                      </div>
                    </div>
                  </Event>
                </motion.div>
                : <></>
            }

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 }
              }}
            >
              <Event
                title='O Día Despois'
                image='spa.jpg'
                description='Na saúde e na enfermidade, na festa e na resaca.
              Pecharemos o acceso ao balneario para tódolos invitados que queden durmir Terán acceso gratuíto durante dúas horas para que podades comentar as mellores xogadas da fin de semana.'
                date='Domingo, 1 de outubro de 2023'
                time='13:00'
                location='Balneario de Arnoia'
                link='https://goo.gl/maps/wFrRD1WjQysSEMbf8'
              />
            </motion.div>
          </div>
        </div>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 }
        }}
      >
        <div className='mt-16'>
          <HText>Confirma a túa asistencia</HText>
          <div className='mt-2'>
            <RsvpForm type={type}/>
          </div>
        </div>
      </motion.div>
    </section>
  )
}

export default WeddingSection
