export const mailerConfig = {
  host: 'mailtrack.rodrigovaamonde.com',
  folder: 'api',
  secure: true,
  auth: {
    username: 'coralvsdavid@anosavoda.com',
    password: '0bz39Eoq9q6!m*WM'
  },
  adminEmail: 'coralomba@gmail.com,davidfernandezlago@gmail.com'
}
