'use client'

import React from 'react'

interface DateTimeDisplayProps {
  value: number
  type: string
  isDanger: boolean
}

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({ value, type, isDanger }) => {
  const classProps = isDanger ? 'text-red-600' : 'text-primary-600'

  return (
    <div className={classProps + ' rounded-md p-2 m-2 text-center'}>
      <p className='font-bold text-2xl md:text-4xl'>{value}</p>
      <span className='text-base md:text-xl'>{type}</span>
    </div>
  )
}

export default DateTimeDisplay
