import { createContext } from 'react'
import type mapboxgl from 'mapbox-gl'

interface MapContextProps {
  isMapReady: boolean
  map?: mapboxgl.Map
  setMap: (map: mapboxgl.Map) => void
}

export const MapContext = createContext<MapContextProps>({
  isMapReady: false,
  setMap: () => {}
})
