import { collection, doc, setDoc, getDocs, serverTimestamp, query } from 'firebase/firestore'
import { type FirebaseResponse, type FormRsvpData } from '../shared/types'
import { FirebaseDB } from '../config/firebase'
import { sendEmailRegistration } from './mailerService'

const collections = {
  rsvp: 'rsvp'
}

export const sendRsvp = async (data: FormRsvpData): Promise<FirebaseResponse<string>> => {
  try {
    data.createdAt = serverTimestamp()

    const newDoc = doc(collection(FirebaseDB, collections.rsvp))
    await setDoc(newDoc, data)

    await sendEmailRegistration(data)

    return {
      success: true,
      data: newDoc.id
    }
  } catch (error: any) {
    console.log('Error adding document: ', error.message)
    return {
      success: false,
      error: error.message
    }
  }
}

export const getAllRsvp = async (): Promise<FirebaseResponse<FormRsvpData[]>> => {
  try {
    const q = query(collection(FirebaseDB, collections.rsvp))

    const docSnap = await getDocs(q)

    if (!docSnap.empty) {
      return {
        success: true,
        data: docSnap.docs.map(doc => doc.data()) as FormRsvpData[]
      }
    } else {
      return {
        success: false,
        error: 'No such document!'
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}
