import React, { type JSX, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const FullEventQR = (): JSX.Element => {
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.event({
      category: 'QR',
      action: 'QR Adestramento',
      label: 'QR Adestramento'
    })
    navigate('/full-event', { replace: true })
  })

  return (
    <div>Redirixindo...</div>
  )
}

export default FullEventQR
