import React from 'react'
import { motion } from 'framer-motion'
import Countdown from '../../components/countdown/Countdown'
import { WEDDING_DATE } from '../../shared/constants'

const UnderConstruction: React.FunctionComponent = () => {
  return (
    <div className='gap-16 md:h-full md:pb-0 px-2'>
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-center justify-center max-w-2xl'>
          <motion.div
            className='py-5'
            initial={{ scale: 0 }}
            animate={{ scale: [1, 0.8, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <img className='w-64' src='/images/logo.png' alt='heart'/>
          </motion.div>
          <h1 className='my-3 text-3xl font-bold text-center text-primary-600'>#ANosaVoda</h1>

          <Countdown targetDate={WEDDING_DATE}/>
        </div>
      </div>
    </div>
  )
}

export default UnderConstruction
