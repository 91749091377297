import React, { type JSX, useContext, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import {
  BALNEARY_LATITUDE,
  BALNEARY_LONGITUDE,
  MAPBOX_GL_TOKEN
} from '../../shared/constants'
import { MapContext } from '../../context/map/MapContext'

const Map = (): JSX.Element => {
  const { setMap } = useContext(MapContext)

  const [mapLoaded, setMapLoaded] = useState(false)

  const mapDiv = useRef<HTMLDivElement>(null)
  mapboxgl.accessToken = MAPBOX_GL_TOKEN

  useEffect(() => {
    if (!mapLoaded) {
      const map = new mapboxgl.Map({
        container: mapDiv.current as HTMLDivElement,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [BALNEARY_LONGITUDE, BALNEARY_LATITUDE],
        zoom: 16
      })
      setMap(map)
      setMapLoaded(true)
    }
  }, [mapLoaded])

  return (
    <div ref={mapDiv}
      className="w-full h-96 rounded-lg"
    >
    </div>
  )
}

export default Map
