import React, { type JSX } from 'react'
import mapboxgl, { Marker } from 'mapbox-gl'
import { MapContext } from './MapContext'
import { mapReducer } from './mapReducer'

export interface MapState {
  isMapReady: boolean
  map?: mapboxgl.Map
}

const INITIAL_STATE: MapState = {
  isMapReady: false,
  map: undefined
}

interface Props {
  children: JSX.Element
}

const MapProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = React.useReducer(mapReducer, INITIAL_STATE)

  const setMap = (map: mapboxgl.Map): void => {
    const myLocationPopup = new mapboxgl.Popup({
      closeButton: false
    }).setHTML(`
        <div>
           <h2 class="text-lg">Arnoia Caldaria Hotel Balneario</h2>
            <br />
            <div>
              <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400"> 
                <li class="flex items-center">
                  <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"></path>
                  </svg>
                  <a href="https://www.caldaria.es/" target="_blank">https://www.caldaria.es/</a>
                </li> 
                <li class="flex items-center">
                  <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"></path>                
                  </svg>
                  <a href="tel: +34988492400">+34 988 49 24 00</a>
                </li>
                <li class="flex items-center">
                  <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path>                
                  </svg>
                  <a href='https://goo.gl/maps/2eu8RbyKYoPKnEY79' target="_blank">Acceso Vila Termal, 1, 32417 Arnoia, Ourense</a>
                </li>
              </ul>
            </div>
        </div>
`)

    new Marker({
      color: '#e32121'
    }).setLngLat(map.getCenter()).addTo(map)
      .setPopup(myLocationPopup)

    dispatch({ type: 'setMap', payload: map })
  }

  return (
    <MapContext.Provider value={{
      ...state,
      setMap
    }}>
      {children}
    </MapContext.Provider>
  )
}

export default MapProvider
