import React, { type JSX } from 'react'
import { type SelectedPage } from '../../shared/types'
import { motion } from 'framer-motion'
import WeddingSection from '../../components/wedding-section/WeddingSection'
import { AssistanceType } from '../../enums/assistanceType'
import Footer from '../../components/footer/Footer'

interface Props {
  isTopOfPage: boolean
  selectedPage: SelectedPage
  setSelectedPage: (page: SelectedPage) => void
}

const PreWedding = ({ isTopOfPage, selectedPage, setSelectedPage }: Props): JSX.Element => {
  return (
    <div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 }
        }}
      >
        <div className="flex justify-center pt-8">
          <img src='/images/logo.png' alt="Logo" className='w-100 mx-auto block'/>
        </div>
      </motion.div>

      <WeddingSection setSelectedPage={setSelectedPage} type={AssistanceType.PreWedding}/>

      <Footer />
    </div>
  )
}

export default PreWedding
