import React, { type JSX } from 'react'

interface Props {
  children: React.ReactNode
}

const HText = ({ children }: Props): JSX.Element => {
  return (
    <h1 className="basis-3/5 text-3xl text-text border-b uppercase">{children}</h1>
  )
}

export default HText
