import React, { type JSX } from 'react'
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline'

interface Props {
  title: string
  image: string
  date: string
  time: string
  description: string
  location: string
  link: string
  children?: JSX.Element
}

const Event = ({ title, image, date, time, description, location, link, children }: Props): JSX.Element => {
  return (
    <div className='mt-32 border border-primary-500 rounded-lg'>
      <div className='-mt-20 w-3/4 mx-auto'>
        <img className='w-full rounded-lg border border-black-50' src={`/images/${image}`} alt='invitacion'/>
      </div>
      <div className='mt-4'>
        <p className='text-center text-3xl text-primary-600 title mt-3'>
          {title}
        </p>
      </div>
      <hr className='w-1/4 my-5 mx-auto'/>
      <div className='mt-4'>
        <p className='text-center text-primary-600 mt-3 px-5 text-justify'>
          {description}
        </p>
      </div>
      <hr className='w-1/4 my-5 mx-auto'/>
      <div className='mb-5'>
        <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 pl-5">
          <li className="flex items-center">
            <CalendarIcon className="w-4 h-4 mr-2" />
            <span>{date}</span>
          </li>
          <li className="flex items-center">
            <ClockIcon className="w-4 h-4 mr-2" />
            <span>{time}</span>
          </li>
          <li className="flex items-center">
            <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path>
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path>
            </svg>
            <a href={link} target="_blank" rel="noreferrer">
              {location}
            </a>
          </li>
        </ul>
        {
          (children != null) && (
            <div>
              <hr className='w-1/4 my-5 mx-auto'/>
              {children}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Event
