import React, { type JSX } from 'react'
import HText from '../commons/HText'
import { LINK_TO_ABBEY } from '../../shared/constants'
import { Carousel } from 'flowbite-react'
import useMediaQuery from '../../hooks/useMediaQuery'

const WhereSleep = (): JSX.Element => {
  const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')

  const whereSleepImages = [
    '/images/where-sleep-1.jpg',
    '/images/where-sleep-2.jpg',
    '/images/where-sleep-3.jpg',
    '/images/where-sleep-4.jpg',
    '/images/where-sleep-5.jpg',
    '/images/where-sleep-6.jpg'
  ]
  return (
    <div className='mt-16 w-full'>
      <HText>Onde Durmir</HText>
      <div className='mt-5'>
        <p className='text-center text-2xl text-primary-600 title mt-3'>Arnoia Caldaria Hotel e Balneario</p>
        <p className='mt-3 text-justify'>
          O hotel ten 89 habitacións, todas exteriores.<br/>
          Todas están perfectamente equipadas para unha estancia cómoda e agradable.
        </p>
        <p className='mt-3'>
          <span className='text-lg text-slate-500 italic block underline'>Prezos</span>
          Habitación Individual: 60€/noite<br/>
          Habitación Sinxela (2 persoas): 90€/noite<br/>
          Habitación Triple (3 persoas): 110€/noite
        </p>

        <div className='my-10'>
          <Carousel
            style={{ height: isAboveMediumScreens ? '500px' : '180px' }}
            slideInterval={5000}
            indicators={true}>
            {
              whereSleepImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt='Habitacións'
                  className='w-full mt-5 rounded-lg'
                />
              ))
            }
          </Carousel>
        </div>

        <div className='mt-5 mx-auto text-center'>
          <a
            href={LINK_TO_ABBEY}
            target='_blank'
            rel='noreferrer'
            className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Como Chegar
          </a>
        </div>
      </div>
    </div>
  )
}

export default WhereSleep
