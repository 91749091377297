import { type FormRsvpData } from '../shared/types'
import { type SendEmailData } from '../shared/sendEmailData'

export const formRsvpDataToSendEmailDataMapper = (data: FormRsvpData): SendEmailData => {
  return {
    to: data.email,
    template: '',
    vars: {
      guestNames: data.names,
      email: data.email,
      assistanceDays: data.assistanceType.join(', '),
      bus: data.bus === '1',
      busWhere: data.busWhere,
      sleep: data.sleep === '1',
      numNights: data.nights,
      numRooms: data.rooms,
      intolerances: data.intolerances,
      comments: data.message
    }
  }
}
