export enum SelectedPage {
  Home = 'Home',
  Wedding = 'Wedding',
  Travel = 'Travel',
  Registry = 'Registry'
}

export interface AssistanceType {
  wedding: boolean
  prewedding: boolean
  training: boolean
  afterDay: boolean
}

export enum FormType {
  WEDDING = 'WEDDING',
  PRE_WEDDING = 'PRE_WEDDING',
  TRAINING = 'TRAINING'
}

export interface FormRsvpData {
  names: string[]
  email: string
  message: string
  intolerances: string
  assistanceType: string[]
  bus: string
  busWhere: string
  sleep: string
  nights: string
  rooms: string
  createdAt: any
}

export interface FirebaseResponse<T> {
  success: boolean
  data?: T
  error?: string
}
