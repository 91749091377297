import React, { type JSX } from 'react'
import { motion } from 'framer-motion'
import DateTimeDisplay from './DateTimeDisplay'
import { useCountdown } from '../../hooks/useCountdown'

interface CountdownProps {
  targetDate: Date
}

const Countdown = ({ targetDate }: CountdownProps): JSX.Element => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  const isExpired: boolean = days + hours + minutes + seconds <= 0

  if (isExpired) {
    return (
      <div className='expired-notice'>
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    )
  } else {
    return (
      <motion.div
        className='rounded-lg mt-5 border border-primary-500 bg-primary-100'
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 }
        }}
      >
        <p className='text-center text-3xl text-primary-600 title mt-3'>
          Soamente
        </p>
        <div className='flex justify-center items-center'>
          <DateTimeDisplay value={days} type='Días' isDanger={days <= 3} />
          <DateTimeDisplay value={hours} type='Horas' isDanger={false} />
          <DateTimeDisplay value={minutes} type='Minutos' isDanger={false} />
          <DateTimeDisplay value={seconds} type='Segundos' isDanger={false} />
        </div>
        <p className='text-center text-3xl text-primary-600 title'>
          Ata a nosa voda
        </p>
      </motion.div>
    )
  }
}

export default Countdown
