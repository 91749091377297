import React from 'react'
import { motion } from 'framer-motion'
import Countdown from '../../components/countdown/Countdown'
import { WEDDING_DATE } from '../../shared/constants'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/24/outline'

const NotFound: React.FC = () => {
  return (
    <div className='gap-16 md:h-full md:pb-0 px-2'>
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-center justify-center max-w-2xl'>
          <motion.div
            className='py-5'
            initial={{ scale: 0 }}
            animate={{ scale: [1, 0.8, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <img className='w-64' src='/images/logo.png' alt='heart'/>
          </motion.div>
          <h1 className='my-3 text-3xl font-bold text-center text-primary-600'>404 - Páxina non atopada</h1>
          <p className='text-center text-gray-600 py-2'>
            Opa, parece que a páxina que estás buscando fuxiu da festa!
          </p>

          <Countdown targetDate={WEDDING_DATE}/>

          <p>
            <Link to="/" className="text-blue-500 mt-4 flex items-center">
              <HomeIcon className="h-5 w-5 mr-2" />
              Volver á páxina de inicio
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
