import React from 'react'
import './App.css'
import AppRouter from './router/AppRouter'
import MapProvider from './context/map/MapProvider'
import PlacesProvider from './context/places/PlacesProvider'

const App: React.FunctionComponent = () => {
  return (
    <PlacesProvider>
      <div className='App'>
        <main>
          <MapProvider>
            <AppRouter/>
          </MapProvider>
        </main>
      </div>
    </PlacesProvider>
  )
}

export default App
