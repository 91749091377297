import React, { type JSX, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { type FirebaseResponse, type FormRsvpData } from '../../shared/types'
import { sendRsvp } from '../../services/firebaseService'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { selYesNo } from '../../shared/constants'
import { AssistanceType } from '../../enums/assistanceType'

interface EventType {
  id: string
  date: string
  title: string
}

interface Props {
  type: AssistanceType
}
const RsvpForm = ({ type }: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
    clearErrors
  } = useForm<FormRsvpData>()

  const [isSending, setIsSending] = useState<boolean>(false)

  const [name, setName] = useState('')
  const [names, setNames] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  register('names', { required: true })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }
  const handleNamesAdd = (): void => {
    if (name !== '') {
      setNames([...names, name])
      setName('')
    }
  }
  const handleNamesDelete = (index: number): void => {
    setNames(names.filter((_, i) => i !== index))
  }

  useEffect(() => {
    setValue('names', names)
    if (!firstLoad) {
      void trigger('names')
    } else {
      setFirstLoad(false)
    }
  }, [names])

  const eventsType: EventType[] = []

  const elementTraining: EventType = {
    id: AssistanceType.Training.valueOf(),
    date: '28 de setembro',
    title: 'O Adestramento'
  }
  const elementPreWedding: EventType = {
    id: AssistanceType.PreWedding.valueOf(),
    date: '29 de setembro',
    title: 'A Previa'
  }
  const elementWedding: EventType = {
    id: AssistanceType.Wedding.valueOf(),
    date: '30 de setembro',
    title: 'A Voda'
  }
  const elementAfterWedding: EventType = {
    id: AssistanceType.AfterDay.valueOf(),
    date: '1 de outubro',
    title: 'O día despois'
  }

  if (type === AssistanceType.Wedding) {
    eventsType.push(elementAfterWedding, elementWedding)
  } else if (type === AssistanceType.PreWedding) {
    eventsType.push(elementAfterWedding, elementWedding, elementPreWedding)
  } else if (type === AssistanceType.Training) {
    eventsType.push(elementAfterWedding, elementWedding, elementPreWedding, elementTraining)
  }
  eventsType.reverse()

  const submitForm = handleSubmit((data: FormRsvpData) => {
    setIsSending(true)
    void sendRsvp(data).then(async r => {
      await showAlert(r)
      setIsSending(false)

      reset()
      setNames([])
      clearErrors('names')
    })
  })

  const showAlert = async (r: FirebaseResponse<string>): Promise<void> => {
    const title = r.success ? 'Grazas!' : 'Ups!'
    const text = r.success ? 'A túa confirmación de asistencia foi enviada correctamente!' : 'Algo foi mal, por favor, tenta de novo mais tarde.'
    const icon = r.success ? 'success' : 'error'
    const confirmButtonText = 'Pechar'
    await Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      customClass: {
        confirmButton: 'bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
      },
      buttonsStyling: false
    })
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <form
        className="mx-auto max-w-md"
        onSubmit={(event) => {
          event.preventDefault()
          void (async () => {
            await submitForm()
          })()
        }}
      >
        <div className="flex flex-wrap -mx-3 mb-6 justify-center">
          <div className="w-full px-3 mb-6 md:mb-0">
            <div className='w-full'>
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                     htmlFor="grid-first-name">
                Convidados
              </label>
              <div className="flex w-full">
                <input
                  className={`appearance-none w-full text-gray-700 border border-r-0 rounded-l py-3 px-4 leading-tight focus:outline-none focus:bg-white ${(errors.names != null) ? 'border-red-500' : ''}`}
                  type="text"
                  placeholder="Insire nomes e apelidos"
                  onChange={handleInputChange}
                  value={name}
                />

                <button
                  type="button"
                  onClick={() => {
                    handleNamesAdd()
                  }}
                  className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline">
                  <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
              </div>
            </div>
            {
              (errors.names != null) &&
              <p className="text-red-500 text-xs italic mt-3">É necesario indicar polo menos un nome</p>
            }
            {
              names.length > 0 &&
              <div className="mt-4">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                       htmlFor="grid-first-name">
                  Nomes inseridos
                </label>
                <ul>
                  {names.map((name, index) => (
                    <li
                      key={index}
                      className="bg-gray-100 border border-gray-300 px-4 py-2 rounded-md mb-2 flex justify-between"
                    >
                      {name}
                      <button
                        type="button"
                        className="text-red-500 hover:text-red-700"
                        onClick={() => {
                          handleNamesDelete(index)
                        }}
                      >
                        <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            }

          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="grid-password">
              O teu email
            </label>
            <input
              className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.email != null) ? 'border-red-500' : ''}`}
              type="text"
              placeholder="O teu enderezo de correo electrónico"
              {...register('email', { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i })}
            />
            {
              (errors.email != null) && errors.email.type === 'required' &&
              <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
            }
            {
              (errors.email != null) && errors.email.type === 'pattern' &&
              <p className="text-red-500 text-xs italic">O enderezo de correo electrónico non é válido</p>
            }
          </div>
        </div>

        <div className='mb-6'>

          <ul
            className={`items-center w-full text-sm font-medium text-gray-900 bg-white border  rounded-lg sm:flex dark:bg-gray-700  dark:text-white ${(errors.assistanceType != null) ? 'border-red-200 dark:border-red-600' : 'border-gray-200 dark:border-gray-600'}`}>
            {eventsType.map((event, index) => (
              <li key={index}
                  className={`w-full border-b  sm:border-b-0 sm:border-r ${(errors.assistanceType != null) ? 'border-red-200 dark:border-red-600' : 'border-gray-200 dark:border-gray-600'}`}>
                <div className="flex items-center pl-3">
                  <input
                    id={`event-${event.id.toString()}`}
                    type="checkbox"
                    value={event.id}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    {...register('assistanceType', { required: true })}
                  />
                  <label
                    htmlFor={`event-${event.id.toString()}`}
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {event.date} - {event.title}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          {
            (errors.assistanceType != null) && errors.assistanceType.type === 'required' &&
            <p className="text-red-500 text-xs italic">Debes seleccionar polo menos un evento</p>
          }
        </div>

        {
          (watch('assistanceType') !== undefined && watch('assistanceType').length === 1 && watch('assistanceType').includes(AssistanceType.Wedding)) &&
          <div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                       htmlFor="grid-password">
                  Quero utilizar o bus como medio de transporte
                </label>
                <select
                  className={`block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.bus != null) ? 'border-red-500' : ''}`}
                  {...register('bus', { required: true })}>
                  <option value="">Indica se precisas bus</option>
                  {
                    selYesNo.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))
                  }
                </select>
                {
                  (errors.bus != null) && errors.bus.type === 'required' &&
                  <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
                }
              </div>
            </div>
          </div>
        }

        {
          (watch('bus') === '1') &&
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                     htmlFor="grid-password">
                Dende onde queres que che recolla o bus?
              </label>
              <select
                className={`block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.bus != null) ? 'border-red-500' : ''}`}
                {...register('busWhere', { required: true })}>
                <option value="">Indica o lugar de recollida</option>
                <option>Vigo - Balaídos</option>
                <option>A Guarda</option>
              </select>
              {
                (errors.busWhere != null) && errors.busWhere.type === 'required' &&
                <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
              }
            </div>
          </div>
        }

        <div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                     htmlFor="grid-password">
                Precisas aloxamento?
              </label>
              <select
                className={`block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.sleep != null) ? 'border-red-500' : ''}`}
                {...register('sleep', { required: true })}>
                <option value="">Indica se precisas aloxamento</option>
                {
                  selYesNo.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                  ))
                }
              </select>
              {
                (errors.sleep != null) && errors.sleep.type === 'required' &&
                <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
              }
            </div>
          </div>
        </div>

        {
          (watch('sleep') === '1') &&
          <div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                       htmlFor="grid-password">
                  Para cantas noites precisas aloxamento?
                </label>
                <select
                  className={`block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.nights != null) ? 'border-red-500' : ''}`}
                  {...register('nights', { required: true })}>
                  <option value="">Indica as noites que precises</option>
                  <option>Só sábado</option>
                  <option>Venres e sábado</option>
                  <option>Xoves, venres e sábado</option>
                </select>
                {
                  (errors.nights != null) && errors.nights.type === 'required' &&
                  <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
                }
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                       htmlFor="grid-password">
                  Cantas habitacións precisas?
                </label>
                <select
                  className={`block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${(errors.rooms != null) ? 'border-red-500' : ''}`}
                  {...register('rooms', { required: true })}>
                  <option value="">Indica as noites que precises</option>
                  <option>Só necesito unha</option>
                  <option>Dúas habitacións</option>
                  <option>Tres habitacións</option>
                  <option>Catro habitacións</option>
                </select>
                {
                  (errors.rooms != null) && errors.rooms.type === 'required' &&
                  <p className="text-red-500 text-xs italic">Este campo é obrigatorio</p>
                }
              </div>
            </div>
          </div>
        }

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="grid-password">
              ¿Tes algunha intolerancia alimentaria?
            </label>
            <textarea
              className="
                appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-36"
              id="grid-password"
              placeholder="Indica as túas intolerancias alimentarias"
              {...register('intolerances')}
            ></textarea>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   htmlFor="grid-password">
              Deixa o teu comentario
            </label>
            <textarea
              className="
                appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-36"
              id="grid-password"
              placeholder="Deixa o teu comentario"
              {...register('message')}
            ></textarea>
          </div>
        </div>

        <div className="flex items-center justify-center">
          {
            isSending &&
            <div className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-600"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"></circle>
                <path className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
              <span>Enviando...</span>
            </div>
          }
          {
            !isSending &&
            <button
              className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit">
              Confirmar Asistencia
            </button>
          }
        </div>
      </form>
    </div>
  )
}

export default RsvpForm
