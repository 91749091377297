import React, { type JSX, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UnderConstruction from '../pages/UnderConstruction/UnderConstruction'
import WeddingQR from '../pages/Wedding/WeddingQR'
import PreWeddingQR from '../pages/PreWedding/PreWeddingQR'
import FullEventQR from '../pages/FullEvent/FullEventQR'
import Wedding from '../pages/Wedding/Wedding'
import PreWedding from '../pages/PreWedding/PreWedding'
import FullEvent from '../pages/FullEvent/FullEvent'
import { SelectedPage } from '../shared/types'
import Export from '../pages/Export/Export'
import ReactGA from 'react-ga4'
import NotFound from '../pages/Error/NotFound'

const titlesByRoute: Record<string, string> = {
  '/': 'Conta Atrás',
  '/wedding': 'A Voda',
  '/pre-wedding': 'A Pre-Voda',
  '/full-event': 'O Adestramento',
  '/P6zFGbw3KhE2Wv6k2K6m/export': 'Exportar',
  '/d67kntnmpv': 'A Voda QR',
  '/finx8dzck8': 'A Pre-Voda QR',
  '/swrmvtgas1': 'O Adestramento QR'
}

const AppRouter = (): JSX.Element => {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(SelectedPage.Home)
  const [isTopOfPage, setIsTopOfPage] = useState<boolean>(true)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: titlesByRoute[window.location.pathname]
    })
    const handleScroll = (): void => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true)
        setSelectedPage(SelectedPage.Home)
      } else {
        setIsTopOfPage(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnderConstruction/>}/>

        <Route path="/d67kntnmpv" element={<WeddingQR/>}/>
        <Route path="/wedding" element={<Wedding selectedPage={selectedPage} isTopOfPage={isTopOfPage}
                                                 setSelectedPage={setSelectedPage}/>}/>

        <Route path="/finx8dzck8" element={<PreWeddingQR/>}/>
        <Route path="/pre-wedding" element={<PreWedding selectedPage={selectedPage} isTopOfPage={isTopOfPage}
                                                        setSelectedPage={setSelectedPage}/>}/>

        <Route path="/swrmvtgas1" element={<FullEventQR/>}/>
        <Route path="/full-event" element={<FullEvent selectedPage={selectedPage} isTopOfPage={isTopOfPage}
                                                      setSelectedPage={setSelectedPage}/>}/>

        <Route path="/P6zFGbw3KhE2Wv6k2K6m/export" element={<Export/>}/>

        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
