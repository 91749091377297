import React, { type JSX, useReducer } from 'react'
import { PlacesContext } from './PlacesContext'
import { placesReducer } from './placesReducer'

export interface PlacesState {
  isLoading: boolean
  userLocation?: [number, number]
}

const INITIAL_STATE: PlacesState = {
  isLoading: true,
  userLocation: undefined
}

interface Props {
  children: JSX.Element
}

const PlacesProvider = ({ children }: Props): JSX.Element => {
  const [state] = useReducer(placesReducer, INITIAL_STATE)

  return (
    <PlacesContext.Provider value={{
      ...state
    }}>
      {children}
    </PlacesContext.Provider>
  )
}

export default PlacesProvider
