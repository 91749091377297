'use client'

import React, { type JSX } from 'react'
import { URL_FIREBASE_STORAGE } from '../../shared/constants'
import { Carousel } from 'flowbite-react'

const StoreCarousel = (): JSX.Element => {
  const slides = [
    {
      type: 'video',
      url: URL_FIREBASE_STORAGE + 'adega-1.mp4?alt=media&token=5eb9dc03-8d78-4044-aa47-20368bef54c3'
    },
    {
      type: 'video',
      url: URL_FIREBASE_STORAGE + 'adega-2.mp4?alt=media&token=cbb1ed8d-40aa-4e4f-b2dc-5f01ba06d869'
    },
    {
      type: 'image',
      url: URL_FIREBASE_STORAGE + 'adega-1.jpg?alt=media&token=f49952cc-2cde-4b4a-a24d-d074cff03326'
    },
    {
      type: 'image',
      url: URL_FIREBASE_STORAGE + 'adega-2.jpg?alt=media&token=2d9fd0a3-88c5-4142-8459-927f4bf5a4d3'
    },
    {
      type: 'image',
      url: URL_FIREBASE_STORAGE + 'adega-3.jpg?alt=media&token=e73d25e2-6ce8-4994-8fc4-93a61096a368'
    },
    {
      type: 'image',
      url: URL_FIREBASE_STORAGE + 'adega-4.jpg?alt=media&token=c7c1ade0-17a4-4619-864a-47b3be49f123'
    },
    {
      type: 'image',
      url: URL_FIREBASE_STORAGE + 'adega-5.jpg?alt=media&token=45c3321d-9a6e-4c20-9607-008e9b520a8b'
    }
  ]

  return (
    <Carousel
      style={{ height: '180px' }}
      slideInterval={15000}
      indicators={true}>
      {
        slides.map((slide, index) => (
          <div key={index} className='flex h-full items-center justify-center '>
            {
              slide.type === 'video'
                ? <video autoPlay muted loop className='w-full'>
                  <source src={slide.url} type="video/mp4"/>
                </video>
                : <img src={slide.url} alt="Adega" className='w-full'/>
            }
          </div>
        ))
      }
    </Carousel>
  )
}

export default StoreCarousel
