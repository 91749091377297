import { type MapState } from './MapProvider'
import type mapboxgl from 'mapbox-gl'

interface MapAction {
  type: 'setMap'
  payload: mapboxgl.Map
}

export const mapReducer = (state: MapState, action: MapAction): MapState => {
  switch (action.type) {
    case 'setMap':
      return {
        ...state,
        isMapReady: true,
        map: action.payload
      }
    default:
      return state
  }
}
